<template>
  <div
    class="managerCenterHome"
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <template v-if="index===0">
      <div class="simpleInfoPanel">
        <h2 class="w-title-gradients">个人信息<a @click="getDataProjList">编辑个人信息</a></h2>
        <ul
          class="infoList"
          v-if="Object.keys(userPlus).length"
        >
          <li><span>登录用户名：</span>{{userPlus.user}}</li>
          <li><span>登录密码：</span>******* <a @click="index=2">修改密码</a></li>
          <li><span>员工姓名：</span>{{userPlus.userName || '--'}}</li>
          <li><span>手机号码：</span>{{userPlus.phone || '--'}}</li>
          <li><span>邮箱：</span>{{userPlus.userEmail || '--'}}</li>
          <li><span>部门：</span>{{userPlus.deploymentName || '--'}}</li>
          <li><span>角色：</span>{{userPlus.roleName || '--'}}</li>
          <li :title="userPlus.projectStr"><span>绑定项目：</span>{{userPlus.projectStr || '--'}}
          </li>
        </ul>
      </div>
      <div class="simpleInfoPanel mt15">
        <h2 class="w-title-gradients">账户信息</h2>
        <ul class="infoList">
          <!-- <li><span>所在企业：</span>{{userPlus.company || '--'}}</li> -->
          <li><span>企业编码：</span>{{userPlus.companyCode || '--'}}</li>
          <li><span>所在企业：</span>{{userPlus.companyStr || '--'}}</li>
          <li><span>有效期至：</span>{{userPlus.endTime || '--'}}</li>
          <li><span>账号类型：</span>{{userPlus.userType | roles}}</li>
        </ul>
      </div>
      <div
        class="simpleInfoPanel mt15"
        v-if="rights && rights.length"
      >
        <h2 class="w-title-gradients">权益使用信息</h2>
        <ul class="dataList">
          <li
            v-for="(item,index) in rights"
            :key="index"
          >
            {{item.title}}<span class="fr">已使用{{item.value+"%"}}</span>
            <el-progress
              :stroke-width="10"
              :show-text="false"
              :percentage="item.value"
              :color="'#26CBE9'"
            ></el-progress>
          </li>
        </ul>
      </div>
    </template>
    <template v-if="index===1">
      <div class="simpleInfoPanel">
        <el-form
          ref="form"
          class="form"
          :model="form"
          label-width="122px"
          inline
        >
          <el-form-item
            label="登录用户名："
            prop="account"
            :label-width="formLabelWidth"
            class="w-input-sty"
            :rules="[{ required: true, message: '请输入登录用户名', trigger: 'blur' }]"
          >
            <el-input
              :disabled="true"
              v-model="form.account"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="员工姓名："
            prop="name"
            :label-width="formLabelWidth"
            :rules="[{ required: true, message: '请输入员工姓名', trigger: 'blur' }]"
          >
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item
            label="手机号码："
            :label-width="formLabelWidth"
          >
            <el-input v-model="form.tel"></el-input>
          </el-form-item>
          <el-form-item
            label="邮箱："
            :label-width="formLabelWidth"
          >
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item
            label="绑定项目："
            :label-width="formLabelWidth"
            prop="projects"
            class="w-input-sty"
          >
            <el-select
              v-model="form.projects"
              disabled
              multiple
              placeholder="--"
            >
              <el-option
                v-for="item in projectOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="部门选择："
            :label-width="formLabelWidth"
            prop="division"
            placeholder="请选择部门"
            class="w-input-sty"
          >
            <el-input
              :disabled="true"
              :title="form.division"
              v-model="form.division"
            ></el-input>
            <!-- <el-cascader
              popper-class="black-select-panel"
              v-model="form.division"
              placeholder="--"
              disabled
              @change="toValidateField('form', 'division')"
              :title="form.projects? '' : '请先选择项目'"
              :rules="[{ required: true, message: '请选择部门', trigger: 'blur' }]"
              :options="divisionOptions"
              :props="{
                expandTrigger: 'hover',
                label: 'name',
                value: 'id',
                children: 'division'
              }"
            ></el-cascader> -->
          </el-form-item>
          <br>
          <el-form-item
            label="角色选择："
            :label-width="formLabelWidth"
            prop="role"
            placeholder="请选择角色"
            class="w-input-sty"
          >
            <el-select
              v-model="form.role"
              :placeholder="form.isAdmin===0?'该账户为主账户':'--'"
              disabled
              @change="toValidateField('form', 'role')"
            >
              <el-option
                v-for="item in roleOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <br>
          <el-button
            class="btnPrimary btnCommon ml40"
            @click="editMember('form')"
          >保存</el-button>
          <el-button
            class="btnCommon"
            @click="index=0"
          >取消</el-button>
        </el-form>
        <br>
      </div>
    </template>
    <template v-if="index===2">
      <div class="simpleInfoPanel">
        <el-form
          ref="form1"
          :model="form1"
          label-width="132px"
          :rules="rules"
          inline
        >
          <el-form-item
            label="旧密码"
            prop="password"
          >
            <el-input
              placeholder="请输入旧密码"
              v-model="form1.password"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="新密码"
            prop="passwordNew"
          >
            <el-input
              class="xlInput"
              v-model="form1.passwordNew"
              type="password"
              placeholder="请输入8-20位英文大小写+数字组合"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="确认新密码"
            prop="passwordNew1"
          >
            <el-input
              class="xlInput"
              v-model="form1.passwordNew1"
              type="password"
              placeholder="请再次输入新密码"
            ></el-input>
          </el-form-item>
          <br>
          <el-form-item label=" ">
            <el-button
              class="btnPrimary btnCommon"
              @click="changePwd('form1')"
            >保存</el-button>
            <el-button
              class="btnCommon"
              @click="resetForm1"
            >取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api'
import { getCookie, removeCookie } from '@/utils'
import { Md5 } from '@/utils/md5'
export default {
  name: 'managerCenterHome',
  components: {},
  data () {
    const checkNewPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        const testPwd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/
        if (testPwd.test(value)) {
          callback()
        } else {
          callback(new Error('密码为8-20位英文大小写+数字组合'))
        }
      }
    }
    const checkNewPwd1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else {
        if (this.form1.passwordNew !== this.form1.passwordNew1) {
          callback(new Error('再次输入的密码不一致'))
        } else {
          callback()
        }
      }
    }
    return {
      rules: {
        password: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
        passwordNew: [{ required: true, validator: checkNewPwd, trigger: 'blur' }],
        passwordNew1: [{ required: true, validator: checkNewPwd1, trigger: 'blur' }]
      },
      userPlus: {},
      index: 0,
      rights: [],
      formLabelWidth: '137px',
      form: {
        id: '',
        isAdmin: 0,
        account: '',
        password: '',
        name: '',
        tel: '',
        email: '',
        division: '',
        role: '',
        projects: ''
      },
      form1: {
        password: '',
        passwordNew: '',
        passwordNew1: ''
      },
      loading: true,
      projectOptions: [],
      divisionOptions: [],
      roleOptions: [],
      interestsData: {}
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.getDataUser()
    if (this.$route.query.index) {
      this.index = Number(this.$route.query.index)
    }
  },
  methods: {
    editMember () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            // exportbrandnum: 0, // 品牌导出数
            // look4link: 0, // 品牌联系特权数
            // lookbrandrightnum: 0, //  查看品牌权益数
            // signednum: 0, // 查看项目权益数
            // membernum: 0, // 子账号配置个数
            // user: 'string', // 登录用户名
            passwd: 'string', // 密码(前端md5一次)
            phone: 'string', // 手机号
            userEmail: '',
            userName: 'string'
            // projectIds: [], // 绑定项目id
            // deploymentId: 0, // 部门id
            // roleId: 'string', // 角色id
            // userId: 0
          }
          // params.userId = this.form.id
          // params.user = this.form.account
          // if (this.form.password) {
          //   params.passwd = ''
          // } else {
          //   params.passwd = this.form.password
          // }
          params.passwd = ''
          params.userName = this.form.name
          params.phone = this.form.tel
          params.userEmail = this.form.email
          // if (this.form.projects && this.form.projects.length) {
          //   params.projectIds = this.form.projects
          // }
          // params.deploymentId = this.form.division
          // params.roleId = this.userPlus.roleId
          this.axios.post(api.updatePersonalInfo, params).then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '编辑成功'
              })
              this.getDataUser()
              this.index = 0
            } else {
            }
          }, rej => {
            console.log(rej)
          }).catch(err => {
            console.log(err)
          })
        } else {
        }
      })
    },
    getDataRoleList () {
      this.axios.post(api.getRoleList, {
        pageSize: 100
      }).then(res => {
        if (res.data.code === 0) {
          this.roleOptions = res.data.data.dataList
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    // getDataDivision (id) {
    //   if (this.index === 1) {
    //     // this.toValidateField('form', 'projects')
    //   }
    //   if (this.form.projects.length > 0) {
    //     this.axios.post(api.departmentManagement, {
    //       projectId: id
    //     }).then(res => {
    //       if (res.data.code === 0) {
    //         const resData = res.data.data
    //         this.divisionOptions = resData.map(item => {
    //           if (item.managementVOS && item.managementVOS.length) {
    //             item.division = item.managementVOS.filter(item1 => {
    //               if (item1.managementVOS && item1.managementVOS.length) {
    //                 item1.division = item1.managementVOS.filter(item2 => {
    //                   return item2.type === 1
    //                 })
    //                 if (!item1.division.length) {
    //                   delete item1.division
    //                 }
    //               }
    //               return item1.type === 1
    //             })
    //           }
    //           return item
    //         })
    //       }
    //     }, rej => {
    //       console.log(rej)
    //     }).catch(err => {
    //       console.log(err)
    //     })
    //   } else {
    //   }
    // },
    toValidateField (formName, field) {
      this.$refs[formName].validateField(field, err => {
        if (!err) {
        } else {
          return false
        }
      })
    },
    getDataProjList () {
      this.index = 1
      this.axios.post(api.getUserProjectList, {
      }).then(res => {
        if (res.data.code === 0) {
          this.projectOptions = res.data.data
          if (this.projectOptions && this.projectOptions.length) {
            // this.getDataDivision(this.projectOptions[0].id)
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      // this.getDataDivision(this.userPlus.projects[0].id)
      this.getDataRoleList()
    },
    changePwd (form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          const md5 = new Md5()
          const md5Pwd = md5.hex_md5(this.form1.password).toUpperCase()
          const md5PwdNew = md5.hex_md5(this.form1.passwordNew).toUpperCase()
          this.axios.post(api.updatePwd, {
            newPwd: md5PwdNew,
            oldPwd: md5Pwd
          }).then(res => {
            console.log('res', res)
            if (res.data.code === 0) {
              this.$message({ type: 'success', message: '密码修改成功' })
              removeCookie('wisdom_token')
              removeCookie('wisdom_user')
              localStorage.removeItem('wisdom_interests')
              window.location.href = '/#/login'
            }
          }, rej => {
            console.log('res', rej)
          }).catch(err => {
            console.log('res', err)
          })
        } else {
        }
      })
    },
    resetForm1 () {
      this.index = 0
    },
    getDataUser () {
      this.axios.post(api.getById, {
        userId: getCookie('wisdom_user')
      }).then(res => {
        if (res.data.code === 0) {
          this.rights = []
          const resData = res.data.data
          this.userPlus = res.data.data
          this.userPlus.projectStr = ''
          for (let i = 0; i < resData.projects.length; i++) {
            const element = resData.projects[i]
            if (i) {
              this.userPlus.projectStr += ' ; ' + element.projectName + '（' + element.id + '）'
            } else {
              this.userPlus.projectStr += element.projectName + '（' + element.id + '）'
            }
          }
          if (resData.topuid === 0) { // 建华说是这个逻辑
            this.userPlus.companyStr = resData.company + '（' + resData.userId + '）'
          } else {
            this.userPlus.companyStr = resData.company + '（' + resData.topuid + '）'
          }
          if (resData.totalmembernum > 0) {
            this.rights.push({
              title: '子账号开通数',
              number: resData.totalmembernum,
              value: resData.totalmembernum ? parseInt((((resData.totalmembernum - resData.membernum) / resData.totalmembernum) * 100).toFixed(2)) : 0
            })
          }
          if (resData.totallookbrandrightnum > 0) {
            this.rights.push({
              title: '品牌查看权益数',
              number: resData.totalmembernum,
              value: resData.totallookbrandrightnum ? parseInt((((resData.totallookbrandrightnum - resData.lookbrandrightnum) / resData.totallookbrandrightnum) * 100).toFixed(2)) : 0
            })
          }
          if (resData.totallook4link > 0) {
            this.rights.push({
              title: '品牌联系特权数',
              number: resData.totallookbrandrightnum,
              num: this.interestsData.totalmembernum,
              value: resData.totallook4link ? parseInt((((resData.totallook4link - resData.look4link) / resData.totallook4link) * 100).toFixed(2)) : 0
            })
          }
          if (this.user.isadmin === 0 && resData.totalmatchnum) {
            this.rights.push({
              title: '品牌智能匹配数',
              number: resData.totalmatchnum,
              value: resData.totalmatchnum ? parseInt((((resData.totalmatchnum - resData.matchnum) / resData.totalmatchnum) * 100).toFixed(2)) : 0
            })
          }
          if (resData.totalsignednum > 0) {
            this.rights.push({
              title: '项目查看权益数',
              number: resData.totalsignednum,
              value: resData.totalsignednum ? parseInt((((resData.totalsignednum - resData.signednum) / resData.totalsignednum) * 100).toFixed(2)) : 0
            })
          }
          if (resData.totalprojectCompartnum > 0) {
            this.rights.push({
              title: '项目对标权益数',
              number: resData.totalprojectCompartnum,
              value: resData.totalprojectCompartnum ? parseInt((((resData.totalprojectCompartnum - resData.projectCompartnum) / resData.totalprojectCompartnum) * 100).toFixed(2)) : 0
            })
          }
          this.form.id = this.userPlus.userId
          this.form.isAdmin = this.userPlus.isadmin
          this.form.account = this.userPlus.user
          this.form.name = this.userPlus.userName
          this.form.tel = this.userPlus.phone
          this.form.email = this.userPlus.userEmail
          this.form.division = this.userPlus.deploymentName
          this.form.role = parseInt(this.userPlus.roleId) || this.userPlus.roleName
          this.form.projects = this.userPlus.projects.map(item => { return item.id })
          this.loading = false
        }
      }, rej => {
        console.log(rej)
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    }
  },
  filters: {
    roles (val) {
      let role = ''
      switch (val) {
        case 0:
          role = '正式账号'
          break
        case 1:
          role = '试用账号'
          break
        case 2:
          role = '演示账号'
          break
        default:
          role = '--'
      }
      return role
    }
  },
  watch: {
    user (newVal, oldVal) {
    }
  }
}
</script>

<style scoped lang="stylus">
.simpleInfoPanel
  width 100%
  .form
    .el-form-item
      width 30%
  ul.dataList
    margin-left 68px
    padding-bottom 34px
    margin-right -122px
    li
      margin 23px 0 6px 0
    li>>>
      display inline-block
      margin 23px 122px 6px 0
      width 25%
      span
        color #A5A5A7
      .el-progress
        margin-top 15px
      .el-progress-bar__outer
        background-color #3d3f45
  ul.infoList
    padding 0 24px 38px 24px
    li
      display inline-block
      min-width 24%
      margin 23px 0 6px 0
      text-indent 20px
      text-align left
      span
        color #A5A5A7
        text-align right
        display inline-block
        width 130px
</style>
